import { ChevronDownIcon } from "@packages/ds";
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import styled, { css as styledCSS } from "styled-components";

import { Button } from "ds/Button";
import Checkbox from "ds/Checkbox";
import { BodyText, Label } from "ds/Typography";

export const Root = styled(AccordionPrimitive.Root)`
  display: flex;
  flex-direction: column;
`;

export const Item = styled(AccordionPrimitive.Item)`
  display: flex;
  flex-direction: column;
`;

export const Content = styled(AccordionPrimitive.Content)<{
  topBorder?: boolean;
}>`
  ${({ topBorder }) =>
    topBorder &&
    styledCSS`
  border-top: 1px solid var(--mode-border-neutral-weakest);
   `};
`;

export const Header = styled(AccordionPrimitive.Header)`
  display: flex;
  align-items: center;
  justify-content: stretch;
  flex: 1 0 32px;
  margin: 0;
`;

export const ListContent = styled(Content)`
  padding-bottom: 8px;
`;

export const ListItem = styled(BodyText).attrs({ size: "small" })`
  color: var(--mode-text-neutral-default);
  margin: 8px 0;
`;

export const ListCheckboxItem = styled(Checkbox)`
  color: var(--mode-text-neutral-default);
  margin: 8px 0;

  ${Label} {
    font-size: 14px;
    font-weight: 400;
    color: var(--mode-text-neutral-default);
  }
`;

export const ListHeaderLayout = styled(AccordionPrimitive.Header)`
  display: flex;
  align-items: center;
  justify-content: stretch;
  border-top: 1px solid var(--mode-border-neutral-weakest);
  margin: 0;

  > * {
    flex: 1 1 auto;
    margin: 8px 0;
  }
  ${ListCheckboxItem} ${Label} {
    font-size: 13px;
    font-weight: 600;
  }
`;

export const FakeLabel = styled(Label).attrs(() => ({ as: "span" }))`
  color: var(--mode-text-neutral-default);
`;

export const Title = styled(BodyText).attrs(() => ({
  size: "medium",
  weight: "bold"
}))`
  color: var(--mode-text-neutral-default);
  flex: 1 0 auto;
  margin: 16px 0;
`;

export const Divider = styled.hr`
  background-color: var(--mode-border-neutral-weakest);
  flex: 1 1 100%;
  margin: 0 16px;
  border: none;
  height: 1px;
`;

export const ClearButton = styled(Button).attrs({ variant: "link" })`
  margin-right: 16px;
`;

export const TriggerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Trigger = styled(AccordionPrimitive.Trigger)`
  background: none;
  border: none;
  display: flex;
  padding-left: 0px;
  justify-content: space-between;
  cursor: pointer;
`;

export const Chevron = styled(ChevronDownIcon)`
  color: var(--mode-vector-neutral-default);
  transition: transform 300ms;
  vertical-align: text-bottom;
  &:hover {
    color: var(--mode-vector-primary-default);
  }
  [data-state="open"] > & {
    transform: rotate(180deg);
  }
`;
