import React, { useEffect, useMemo, useState } from "react";

import { LoadingIcon as Loading } from "@packages/ds";
import { useIntl, FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import NewProjectButton from "Components/NewProjectButton";
import Button, { Link } from "ds/Button";
import * as Dialog from "ds/Dialog";
import * as Dropdown from "ds/Dropdown";
import Tooltip from "ds/Tooltip";
import * as Typography from "ds/Typography";
import useDecodedParams from "Hooks/useDecodedParams";
import { useLocalForage } from "Hooks/useLocalForage";
import { getSupportUrl } from "Libs/getUrl/getUrl";
import { includesAny } from "Libs/utils";
import { meSelector } from "Reducers/app/selectors";
import {
  selectSubscribeFlowEnabled,
  selectFreeTrialDialogEnabled
} from "Reducers/featureFlags/featureFlags.selectors";
import { organizationByDescriptionIdSelector } from "Reducers/organization";
import { useTrialInfo } from "Reducers/organization/hooks/useTrialnfo";
import {
  hasProjectCreatePermissionSelector,
  loggedInMemberDataSelector
} from "Reducers/organization/loggedInMember/loggedInMember.selectors";
import { loadLoggedInMember } from "Reducers/organization/loggedInMember/thunks/loadLoggedInMember";
import {
  getOrganizationProfile,
  organizationProfileSelector
} from "Reducers/organization/profile";
import useProvisioningPuller from "Reducers/organization/projects/hooks/useProvisioningPuller";
import {
  selectSubscriptionDetails,
  selectOrganizationSubscriptions
} from "Reducers/organization/subscription";
import {
  gitProjectSelector,
  isLoadingExtendedProjectAccessSelector,
  loadOneProject
} from "Reducers/project/project";
import { useExtendedProjectAccessByOrganizationId } from "Reducers/project/project/hooks/useExtendedProjectAccessByOrganizationId";
import { useAppSelector, useAppDispatch } from "Store/hooks";

import { SubscribeCtaBox } from "../SubscribeCtaBox/SubscribeCtaBox";

import * as S from "./FreeTrialDialog.styles";

const FreeTrialDialog = ({ organizationId, setIsCreateProjectModalOpen }) => {
  const dispatch = useAppDispatch();

  const organizationProfile = useAppSelector(state =>
    organizationProfileSelector(state, {
      organizationId
    })
  );

  const isExtendedAccessLoading = useAppSelector(
    isLoadingExtendedProjectAccessSelector
  );

  const navigate = useNavigate();

  const organization = useAppSelector(state =>
    organizationByDescriptionIdSelector(state, {
      organizationDescriptionId: organizationId
    })
  );
  const me = useAppSelector(meSelector);

  useEffect(() => {
    organization?.id && dispatch(loadLoggedInMember(organization?.id));
  }, [dispatch, organization]);

  const memberData = useAppSelector(loggedInMemberDataSelector);

  const isOwner = organization?.owner_id === me?.id;
  const hasPermissionsToEdit =
    isOwner ||
    (memberData &&
      includesAny(memberData?.member.permissions, ["admin", "members"]));

  const hasProjectCreatePermission = useAppSelector(
    hasProjectCreatePermissionSelector
  );

  const [shouldHide, setShouldHide] = useState(false);

  const canEditBilling =
    organization?.owner_id === me?.id || organization?.hasLink("address");

  const { projectId: paramsProjectId } = useDecodedParams<{
    projectId: string;
  }>();

  const { projects: projectsList, load } =
    useExtendedProjectAccessByOrganizationId({
      organizationId: organization?.id!
    });

  const projectId = paramsProjectId || projectsList[0]?.id;

  const project = useAppSelector(state =>
    gitProjectSelector(state, {
      organizationId,
      projectId
    })
  );

  const subscription = useAppSelector(state =>
    selectSubscriptionDetails(state, {
      organizationId,
      subscriptionId: project?.subscription_id
    })
  );
  const hasProjectAndSubscription = project && subscription;

  const isFreeTrialDialogEnabled = useAppSelector(selectFreeTrialDialogEnabled);
  const {
    value: isTrialDialogOpen,
    setValue: setIsTrialDialogOpen,
    isLoading: isLoadingDialog
  } = useLocalForage<boolean>("free_trial_dialog");

  const subscribeCtaEnabled = useAppSelector(selectSubscribeFlowEnabled);
  const orgSubscriptions = useAppSelector(state =>
    selectOrganizationSubscriptions(state, { organizationId })
  );

  const intl = useIntl();

  const { config, trialDays, isSuspended } = useTrialInfo();
  const { tagText, tagVariant = "rose" } = config;

  const projectConsumption = useMemo(() => {
    const sub = subscription || orgSubscriptions[0];
    return sub?.id
      ? organizationProfile?.resources_limit?.used?.projects?.find(
          elt => elt.subscription_id == Number(sub?.id)
        )
      : organizationProfile?.resources_limit?.used;
  }, [subscription, orgSubscriptions, organizationProfile]);
  const title = useMemo(() => {
    return project?.title || orgSubscriptions[0]?.project_title;
  }, [project, orgSubscriptions]);

  const resourcesUsed = organizationProfile?.resources_limit?.used;

  const resourcesLimit = organizationProfile?.resources_limit?.limit;

  const supportUrl = getSupportUrl({ username: organizationId });

  const onLinkContinuousProfilingClick = () => {
    if (project) {
      navigate(
        `/${organization?.name}/${project.id}/${project.default_branch}/profiling`
      );
      setIsTrialDialogOpen(false);
    } else {
      setIsCreateProjectModalOpen(true);
      setIsTrialDialogOpen(false);
    }
  };

  useEffect(() => {
    if (isTrialDialogOpen === null && !isLoadingDialog) {
      setIsTrialDialogOpen(
        organizationId !== undefined && projectsList?.length === 1
      );
    }
  }, [
    me,
    isTrialDialogOpen,
    isLoadingDialog,
    organizationId,
    setIsTrialDialogOpen,
    projectsList?.length
  ]);
  const [hasFinalized, setHasFinalized] = useState(false);

  const { provisioningProject } = useProvisioningPuller();

  useEffect(() => {
    if (hasFinalized || !projectId) return;
    if (!provisioningProject) {
      load();
      dispatch(
        loadOneProject({
          organizationId: organization?.id!,
          projectId: projectId,
          loadActivitiesAndSubscribe: false
        })
      );
      dispatch(getOrganizationProfile({ organizationId }));

      setHasFinalized(true);
    }
  }, [
    dispatch,
    hasFinalized,
    load,
    organization?.id,
    organizationId,
    projectId,
    provisioningProject
  ]);

  const isNegativeTrialDays = trialDays <= 0;

  return (
    <>
      <Dropdown.Root
        id="free_trial_dialog"
        open={isTrialDialogOpen ?? false}
        onOpenChange={() => setIsTrialDialogOpen(!isTrialDialogOpen)}
      >
        <S.Trigger $freeTrialDialogEnabled={!!isFreeTrialDialogEnabled}>
          <S.Trial variant={tagVariant}>
            {intl.formatMessage({
              id: `trial_state.${tagText}`
            })}
          </S.Trial>
        </S.Trigger>
        {isTrialDialogOpen &&
          !isLoadingDialog &&
          organizationId !== undefined &&
          isFreeTrialDialogEnabled && (
            <S.Content shouldHide={shouldHide}>
              <S.CloseDialog onClick={() => setIsTrialDialogOpen(false)} />
              <S.Header>
                <Dialog.Title weight="bold">
                  <FormattedMessage id="free_trial" />
                </Dialog.Title>
                {!hasProjectAndSubscription && (
                  <S.CreateProject weight="regular" size="small">
                    {intl.formatMessage({
                      id: "trial_dialog.create_project"
                    })}
                  </S.CreateProject>
                )}
                {resourcesLimit && (
                  <Typography.BodyText weight="regular" size="small">
                    {intl.formatMessage(
                      { id: "trial_dialog.resources_limit" },
                      resourcesLimit
                    )}
                  </Typography.BodyText>
                )}
                <Typography.BodyText weight="regular" size="small">
                  {intl.formatMessage<React.ReactNode>(
                    { id: "trial_dialog.sellables" },
                    {
                      linkUserManagement: text =>
                        hasPermissionsToEdit ? (
                          <>
                            {organization ? (
                              <Link
                                onClick={() => setIsTrialDialogOpen(false)}
                                analyticId="link_user_management"
                                to={`/${organization?.name}/-/settings/teams/-/create`}
                              >
                                {text}
                              </Link>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <>
                            <Tooltip
                              tooltip={intl.formatMessage({
                                id: "trial.dialog.sellables.user_management_disabled"
                              })}
                              analyticId="trial.dialog.sellables.user_management_disabled"
                            >
                              {" "}
                              <Link
                                disabled
                                to={`/${organization?.name}/-/settings/teams/-/create`}
                              >
                                {text}
                              </Link>
                            </Tooltip>
                          </>
                        ),
                      linkContinuousProfiling: text => (
                        <Button
                          variant="link"
                          onClick={onLinkContinuousProfilingClick}
                          analyticId="link_continuous_profiling"
                        >
                          {text}
                        </Button>
                      )
                    }
                  )}
                </Typography.BodyText>
              </S.Header>
              <S.FreeTrialData>
                <S.Consumption>
                  {isExtendedAccessLoading ? (
                    <Loading />
                  ) : hasProjectAndSubscription ? (
                    <S.FreeTrialRemaining>
                      <Typography.Title tag="h2">
                        {intl.formatMessage(
                          { id: "trial_dialog.remaining_days" },
                          { count: isNegativeTrialDays ? 0 : trialDays }
                        )}
                      </Typography.Title>
                      <S.FreeTrialTitle>
                        <S.ProjectTitle>{title}</S.ProjectTitle>
                        <Typography.BodyText weight="semiBold" size="small">
                          {intl.formatMessage(
                            { id: "trial_dialog.remaining_environments" },
                            {
                              limit: resourcesLimit?.environments || 0,
                              used:
                                projectConsumption?.environments ||
                                projectConsumption?.projects?.[0]
                                  ?.environments ||
                                0
                            }
                          )}
                        </Typography.BodyText>
                      </S.FreeTrialTitle>
                    </S.FreeTrialRemaining>
                  ) : (
                    <>
                      <S.FreeTrialTitleButton $isColumn={!!provisioningProject}>
                        <Typography.Title tag="h2">
                          {intl.formatMessage(
                            { id: "trial_dialog.remaining_days" },
                            { count: isNegativeTrialDays ? 0 : trialDays }
                          )}
                        </Typography.Title>
                        {provisioningProject ? (
                          <S.ProjectBeingCreatedContainer>
                            <Typography.BodyText weight="semiBold" size="small">
                              {intl.formatMessage(
                                { id: "trial_dialog.project_being_created" },
                                {
                                  limit: resourcesLimit.environments,
                                  used:
                                    projectConsumption.environments ||
                                    projectConsumption.projects?.[0]
                                      ?.environments ||
                                    0
                                }
                              )}
                            </Typography.BodyText>
                            <Loading />
                          </S.ProjectBeingCreatedContainer>
                        ) : (
                          <NewProjectButton
                            organizationId={organizationId}
                            isLink
                            linkVariant="outline"
                            onOpenModal={() => setShouldHide(true)}
                            onCloseModal={() => setShouldHide(false)}
                            disabled={isSuspended}
                          />
                        )}
                      </S.FreeTrialTitleButton>
                    </>
                  )}
                </S.Consumption>

                {resourcesUsed && resourcesLimit && (
                  <>
                    <S.Separator />

                    <Typography.Label textStyle="default">
                      {intl.formatMessage({ id: "trial_dialog.consumption" })}
                    </Typography.Label>

                    <S.Consumption>
                      <S.ConsumptionLabel>
                        {intl.formatMessage({ id: "cpu_dialog" })}
                      </S.ConsumptionLabel>
                      <S.ConsumptionValue>
                        {resourcesUsed?.totals?.cpu || 0}{" "}
                        {intl.formatMessage({ id: "cpu_dialog" })}
                        <span>
                          {" "}
                          / {resourcesLimit.cpu}{" "}
                          {intl.formatMessage({ id: "cpu_dialog" })}
                        </span>
                      </S.ConsumptionValue>
                    </S.Consumption>
                    <S.Consumption>
                      <S.ConsumptionLabel>
                        {intl.formatMessage({ id: "ram_dialog" })}
                      </S.ConsumptionLabel>
                      <S.ConsumptionValue>
                        {resourcesUsed.totals?.memory || 0} GB
                        <span> / {resourcesLimit.memory} GB</span>
                      </S.ConsumptionValue>
                    </S.Consumption>
                    <S.Consumption>
                      <S.ConsumptionLabel>
                        {intl.formatMessage({ id: "disk_dialog" })}
                      </S.ConsumptionLabel>
                      <S.ConsumptionValue>
                        {resourcesUsed.totals?.storage || 0} GB
                        <span> / {resourcesLimit.storage} GB</span>
                      </S.ConsumptionValue>
                    </S.Consumption>
                  </>
                )}
              </S.FreeTrialData>

              {typeof supportUrl === "string" && (
                <Typography.BodyText weight="semiBold" size="small">
                  {intl.formatMessage<React.ReactNode>(
                    { id: "trial_dialog.more_time" },
                    {
                      a: chunk => (
                        <S.LinkSupport to={supportUrl}>{chunk}</S.LinkSupport>
                      )
                    }
                  )}
                </Typography.BodyText>
              )}
              {subscribeCtaEnabled && (
                <SubscribeCtaBox
                  showIllustration
                  bodyTextWidth="304"
                  onSubscribeClick={() => {
                    setIsTrialDialogOpen(false);
                  }}
                  organizationId={organizationId}
                  canSubscribe={canEditBilling && hasProjectCreatePermission}
                />
              )}
            </S.Content>
          )}
      </Dropdown.Root>
    </>
  );
};

export default FreeTrialDialog;
